import React from 'react'
import SEO from '../components/seo'
import Button from '../components/button'

const IndexPage = () => (
  <>
    <SEO title="404 Page" />
    <article className="basicPageContainer">
      <h1>How'd ya get here?</h1>
      <p className="quick-text">
        This page does not exist. Sorry about that! Give us a call and we'll help you find
        what you're looking for.
      </p>
      <Button big text="Contact" link="/contact" />
    </article>
  </>
)

export default IndexPage
